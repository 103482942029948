<template>
  <!-- <v-main>
    <router-view ref="component" :parentComponent="parentComponent"/>
    <dashboard-core-footer />
  </v-main> -->
  <v-content color="white">
    <router-view ref="component" :parentComponent="parentComponent"/>
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',
    props: ['parentComponent'],

    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  
    methods: {
      selectLanguage (value) {
        console.log(value, 'selectLanguage')
        var child = this.$refs.component
        console.log(child, 'child')
        child.selectLanguage(value)
      },
    }
  }
</script>
